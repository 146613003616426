import { download, Profile, fetchIcon, escape, isurl, AppleUUID, VERSION, CODENAME } from './util.js'
import { MixDB, IDBConnector, StorageConnector } from './mixdb.js'

// use a MixDB to increase stability and reliability
// link an idb connector and fallback to localStorage
// if both fail, in-memory storage is used
let db = new MixDB('pwastore', '200')
  .store('sources', 'id')
  .link(new StorageConnector(localStorage))

if (typeof Proxy === 'function') {
  import('idb').then(({ openDB }) => {
    db.link(new IDBConnector(openDB, {
      upgrade(db) {
        try {
          db.createObjectStore('sources', { keyPath: 'id', autoIncrement: false })
        } catch (e) { console.warn(e) }
      }
    }))
  })
}

const matchSearch = (pwa, search) => {
  search = search.toLowerCase()
  return search.includes(pwa.name.toLowerCase()) || pwa.name.toLowerCase().includes(search) ||
    search.includes(pwa.description.toLowerCase()) || pwa.description.toLowerCase().includes(search) ||
    pwa.id.toLowerCase() == search
}

const buildAppProfile = async (source, pwa) => {
  const p = new Profile(source.id + '.' + pwa.id, pwa.name, pwa.description, 1, `PWA Store v${VERSION}`, AppleUUID())
  p.webClip(pwa.name, pwa.url, await fetchIcon(pwa.icon), 1, AppleUUID())

  return p
}

const downloadProfile = async (source, pwa) => {
  if (typeof BigInt !== 'function')
    return download(pwa.id + '.pwastore.mobileconfig', (await buildAppProfile(source, pwa)).url())

  const hexkeymat = localStorage.getItem('pwastore_keymat')
  const hexserial = localStorage.getItem('pwastore_serial')
  const strexpiry = localStorage.getItem('pwastore_expiry')

  if (hexkeymat && pwa.id == 'storefront') {
    pwa.url += '?k=' + hexkeymat
    pwa.url += '&s=' + hexserial
    pwa.url += '?e=' + strexpiry
  }

  if (!!localStorage.getItem('pwastore_signedprofile') && hexkeymat && hexserial && strexpiry) {
    const keymat = Uint8Array.from(hexkeymat.match(/.{1,2}/g).map(byte => parseInt(byte, 16)))
    const serial = Uint8Array.from(hexserial.match(/.{1,2}/g).map(byte => parseInt(byte, 16)))
    const expiry = parseInt(strexpiry)

    const { signProfile } = await import('./cert.js')

    const blob = await signProfile(await buildAppProfile(source, pwa), keymat, serial, expiry, pwa.id == 'storefront')
    return download(pwa.id + '.pwastore.mobileconfig', URL.createObjectURL(blob))
  }

  return download(pwa.id + '.pwastore.mobileconfig', (await buildAppProfile(source, pwa)).url())
}

const renderPWA = (div, src, pwa) => {
  let cnt = div
  if (!('_pwa' in div)) {
    cnt = document.createElement('div')
    cnt.addEventListener('click', () => route(`?app=${encodeURIComponent(pwa.id)}&source=${encodeURIComponent(src.id)}`))
    div.appendChild(cnt)
  }
  cnt.classList.add('list-item')
  cnt.innerHTML = `
    <img style="cursor:pointer" class="appimg" src="${pwa.icon?.replace(/"/g, '')}" width="64" height="64" alt="" onerror="this.src='thirdparty/notfound.png'">
    <div class="list-item-desc">
      <span style="cursor:pointer">${escape(pwa.name)}</span><br>
      <small class="text-faded" style="cursor:pointer">${escape(pwa.short || src.name)}</small><br>
    </div>`
}

const renderList = (el, src, pwa) => {
  const div = document.createElement('div')
  div.classList.add('explorelist-discv-item')
  renderPWA(div, src, pwa)
  div.dataset.id = `${src.id}.${pwa.id}`
  el.appendChild(div)
  return div
}

const browseRoute = async params => {
  $('#explore').style.display = ''

  const elems = document.querySelectorAll('.explorelist-discv-item')
  const query = params.get('search')

  for (const el of elems) {
    const visible = !query || matchSearch(el._pwa, query)
    el.style.display = visible ? '' : 'none'
  }
}

const setupBrowse = async () => {
  const el = $('#explore-content')
  let hasLoaded = false

  const srcs = await db.getAll('sources')
  async function renderSource(src) {
    if (!hasLoaded) {
      hasLoaded = true
      el.innerHTML = ''
    }
    for (const pwa of src.pwas) {
      const eel = $(`.explorelist-discv-item[data-id='${src.id}.${pwa.id}']`)
      if (eel) {
        if (JSON.stringify(eel._pwa) != JSON.stringify(pwa))
          renderPWA(eel, src, pwa)
        eel._pwa = pwa
        eel._source = src
      } else {
        const item = renderList(el, src, pwa)
        item._pwa = pwa
        item._source = src
      }
    }
  }
  for (const src of srcs) {
    if (src.cache)
      renderSource(src.cache)
    else
      fetch(src.url).then(x => x.json()).then(renderSource)
  }

  if (srcs.length < 1) {
    el.innerHTML = `<span id="explorelist-noitems">Seems like there's no apps here. Try refreshing your sources or relaunching the app.</span>`
  } else {
    $('#explorelist-noitems')?.remove()
  }
}

const appRoute = async params => {
  $('#app').style.display = ''
  $('#app').classList.add('app-view-show')
  $('#app-warning').style.display = 'none'
  $('#app-item-container').style.display = 'none'

  $('#app .app-customize-btn').style.display = localStorage.getItem('pwastore_customize') ? '' : 'none'

  let src
  if (params.get('srcurl')) {
    src = await (await fetch(params.get('source'))).json()
    src.cache = src
  } else {
    src = await db.get('sources', params.get('source'))
  }
  if (!src) {
    $('#app-warning').style.display = ''
    $('#app-warning').textContent = "The app's source is not installed and could not be found. Please add the source first."
    return
  }
  const source = src.cache || await (await fetch(src.url)).json()
  const pwa = source.pwas.find(app => app.id == params.get('app'))
  if (!pwa) {
    $('#app-warning').style.display = ''
    $('#app-warning').textContent = "The app could not be found in the source. It may have been deleted."
    return
  }

  const install = async () => {
    const ua = navigator.userAgent.toLowerCase()
    const isAndroid = ua.indexOf("android") > -1

    if (isAndroid) {
      if (pwa.platform?.indexOf('android') >= 0 || confirm('This app is not supported on Android. Open anyway?'))
        window.open(pwa.url)
    } else {
      downloadProfile(source, pwa)
    }
  }

  if (pwa.url.trim().startsWith('j')) {
    $('#app-warning').style.display = ''
    $('#app-warning').textContent = "This app has been detected as malicious and cannot be viewed. If you think this is an error, report it to the app's source."
    return
  }

  $('#app-item-icon').src = pwa.icon || 'a'
  $('#app-item-title').textContent = pwa.name || 'Unnamed App'
  $('#app-item-short').textContent = pwa.short ?? ''
  $('#app-item-install').onclick = install
  $('#app-item-desc').textContent = pwa.description || 'No description.'

  $('#app-item-dev').style.display = pwa.dev ? '' : 'none'
  $('#app-item-funct').style.display = pwa.verified ? 'none' : ''

  $('#app-item-advanced-id').textContent = `${source.id}.${pwa.id}`
  $('#app-item-advanced-source').textContent = source.name || source.id
  $('#app-item-advanced-url').href = pwa.url
  $('#app-item-advanced-url').textContent = pwa.url
  $('#app-item-advanced-license').textContent = pwa.license || 'Proprietary'
  $('#app-item-advanced-platform').textContent = (pwa.platform || ['ios']).map(p => {
    if (p.toLowerCase() == 'ios') return 'iOS'
    if (p.toLowerCase() == 'ipados') return 'iPadOS'
    if (p.toLowerCase() == 'macos') return 'macOS'

    return p[0].toUpperCase() + p.substring(1).toLowerCase()
  }).join(', ')

  $('#app-item-iap').style.display = (pwa.iap && pwa.iap.length > 0) ? '' : 'none'
  if (pwa.iap && pwa.iap.length > 0) {
    $('#app-item-iap-container').innerHTML = ''
    for (let i = 0; i < pwa.iap.length; i++) {
      $('#app-item-iap-container').appendChild(
        mDiv().c('settings-row').cc([
          mSpan().c('text-faded').t(pwa.iap[i].name),
          mSpan().s('text-align:right').t(pwa.iap[i].price),
        ])
      )

      if (i < pwa.iap.length - 1)
        $('#app-item-iap-container').appendChild(mEl('hr'))
    }
  }

  $('#app-item-customize').onclick = async () => {
    $('#customize').style.display = ''
    $('body').style.overflowY = 'hidden'
    $('#customize').classList.remove('sheet-hide')
    $('#customize').parentNode.children[0].style.display = ''
    $('#customize').parentNode.children[0].classList.remove('sheet-hide')

    $('#cstmz-icon').onclick = async () => {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'image/png, image/jpeg, image/jpg'
      input.addEventListener('change', () => {
        if (input.files.length > 0) {
          const fr = new FileReader()
          fr.addEventListener('load', () => {
            $('#cstmz-icon').src = fr.result
          })
          fr.readAsDataURL(input.files[0])
        }
        console.log(input.files)
      })
      input.click()
    }
    $('#cstmz-icon').src = pwa.icon || 'a'
    $('#cstmz-title').value = ''
    $('#cstmz-title').placeholder = pwa.name || 'Unnamed App'

    $('#cstmz-btn').onclick = async () => {
      downloadProfile(source, {
        ...pwa,
        name: $('#cstmz-title').value || $('#cstmz-title').placeholder,
        icon: $('#cstmz-icon').src || pwa.icon
      })
    }
  }

  $('#app-item-container').style.display = ''
}

const hideCustomize = () => {
  $('body').style.overflowY = ''
  $('#customize').classList.add('sheet-hide')
  $('#customize').parentNode.children[0].classList.add('sheet-hide')
  setTimeout(() => $('#customize').style.display = 'none', 350)
}

const settingsRoute = () => {
  $('#settings').style.display = ''
}

let _debugspamcheck = 0
const setupSettings = () => {
  $('#stg-debug').checked = !!localStorage.getItem('pwastore_debug')
  $('#stg-debug').addEventListener('change', () => {
    if (_debugspamcheck == 0) {
      setTimeout(() => {
        if (_debugspamcheck > 5) {
          localStorage.setItem('pwastore_experiments', (localStorage.getItem('pwastore_experiments') == 'yes') ? '' : 'yes')

          $('#stg-debug').parentElement.classList.add('switch-special')
          $('#stg-debug').checked = false
          setTimeout(() => {
            $('#stg-debug').parentElement.classList.remove('switch-special')
            $('#stg-debug').checked = !!localStorage.getItem('pwastore_debug')
            setTimeout(() => location.href = '/', 400)
          }, 400)
        }
        _debugspamcheck = 0
      }, 1000)
    }
    _debugspamcheck++
    localStorage.setItem('pwastore_debug', $('#stg-debug').checked ? 'yes' : '')
  })

  $('#stg-customize').checked = !!localStorage.getItem('pwastore_customize')
  $('#stg-customize').addEventListener('change', () => {
    localStorage.setItem('pwastore_customize', $('#stg-customize').checked ? 'yes' : '')
  })

  $('#stg-autorefresh').checked = !!localStorage.getItem('pwastore_autorefresh')
  $('#stg-autorefresh').addEventListener('change', () => {
    localStorage.setItem('pwastore_autorefresh', $('#stg-autorefresh').checked ? 'yes' : '')
  })

  $('#experiments').style.display = (localStorage.getItem('pwastore_experiments') == 'yes') ? '' : 'none'
  $('#tab-themes').style.display = (localStorage.getItem('pwastore_experiments') == 'yes') ? '' : 'none'
  $('#tab-myapps').style.display = (localStorage.getItem('pwastore_experiments') == 'yes') ? '' : 'none'
  $('#stg-pwastore-ver').textContent = `v${VERSION} "${CODENAME}"`
  $('#stg-pwastore-bdg').style.display = (localStorage.getItem('pwastore_experiments') == 'yes') ? '' : 'none'

  $('#stg-signedprofile').checked = !!localStorage.getItem('pwastore_signedprofile')
  $('#stg-signedprofile').addEventListener('change', () => {
    localStorage.setItem('pwastore_signedprofile', $('#stg-signedprofile').checked ? 'yes' : '')
  })
}

const refreshAllSources = async () => {
  if ($('#src-refresh-btn').classList.contains('disabled'))
    return

  $('#src-refresh-btn').classList.add('disabled')

  $('#sources .bottom-progress').style.width = '0%'
  $('#sources .bottom-progress').classList.remove('bottom-progress-done')
  setTimeout(() => $('#sources .bottom-progress').style.width = '25%', 1)

  await new Promise(res => setTimeout(res, 250))

  const srcs = await db.getAll('sources')
  let i = 0
  for (const src of srcs) {
    try {
      const json = await (await fetch(src.url)).json()
      await db.put('sources', { id: json.id, icon: json.icon, name: json.name, url: src.url, cache: json })
      await new Promise(res => setTimeout(res, 10))
    } catch (e) {
      console.error(e)
    } finally {
      i++
      $('#sources .bottom-progress').style.width = `${(25 + (i / srcs.length) * 65).toFixed(0)}%`
    }
  }

  await new Promise(res => setTimeout(res, 250))

  $('#sources .bottom-progress').style.width = '100%'
  setTimeout(() => $('#sources .bottom-progress').classList.add('bottom-progress-done'), 350)
  setTimeout(() => $('#sources .bottom-progress').style.width = '0%', 700)
  $('#src-refresh-btn').classList.remove('disabled')
  setupBrowse() // fully reload the app list
}

const addSource = async src => {
  if (!isurl(src))
    return
  const json = await (await fetch(src)).json()

  // 99.9% sure this is a source and not random data
  if (json && json.id && json.name && json.pwas) {
    if (confirm(`Would you like to add the source "${json.name}"?`)) {
      await db.put('sources', { id: json.id, icon: json.icon, name: json.name, url: src, cache: json })
      setupBrowse() // fully reload the app list
      reloadSources()
    }
  }
}

const deleteSource = async srcid => {
  if (!confirm('Are you sure you want to delete this source?'))
    return
  await db.delete('sources', srcid)
  setupBrowse() // fully reload the app list
  reloadSources()
}

const hideSources = () => {
  $('body').style.overflowY = ''
  $('#sources').classList.add('sheet-hide')
  $('#sources').parentNode.children[0].classList.add('sheet-hide')
  setTimeout(() => $('#sources').style.display = 'none', 350)
  history.pushState(null, null, '?')
}

// microElements
const mEl = (t, id) => {
  const e = document.createElement(t)
  if (id) e.id = id
  e.c = cl => { e.classList.add(cl); return e }
  e.t = t => { e.textContent = t; return e }
  e.h = h => { e.innerHTML = h; return e }
  e.a = (a, v) => { e[a] = v; return e }
  e.o = (n, h) => { e.addEventListener(n, h); return e }
  e.cc = cs => { for (const c of cs) e.appendChild(c); return e }
  e.s = s => { e['style'] = s; return e }
  return e
}
const mDiv = id => mEl('div', id)
const mImg = id => mEl('img', id)
const mBr = id => mEl('br', id)
const mSpan = id => mEl('span', id)

const reloadSources = async () => {
  const srcs = await db.getAll('sources')

  $('#srcs-list').innerHTML = ''
  for (const src of srcs) {
    $('#srcs-list').appendChild(
      mDiv().c('list-item').cc([
        mImg().c('appimg').a('src', src.icon).a('alt', '').a('width', '64').a('height', '64'),
        mDiv().c('list-item-desc').s('width:100%').cc([
          mSpan().t(src.name),
          mBr(),
          mSpan()
            .s('margin-top:-1.4rem;float:right;cursor:pointer;color:#FF453A')
            .o('click', () => deleteSource(src.id))
            .h('<svg width="19.2676" height="23.4863" fill="currentColor"><path d="M6.5625 18.6035C6.93359 18.6035 7.17773 18.3691 7.16797 18.0273L6.86523 7.57812C6.85547 7.23633 6.61133 7.01172 6.25977 7.01172C5.88867 7.01172 5.64453 7.24609 5.6543 7.58789L5.94727 18.0273C5.95703 18.3789 6.20117 18.6035 6.5625 18.6035ZM9.45312 18.6035C9.82422 18.6035 10.0879 18.3691 10.0879 18.0273L10.0879 7.58789C10.0879 7.24609 9.82422 7.01172 9.45312 7.01172C9.08203 7.01172 8.82812 7.24609 8.82812 7.58789L8.82812 18.0273C8.82812 18.3691 9.08203 18.6035 9.45312 18.6035ZM12.3535 18.6035C12.7051 18.6035 12.9492 18.3789 12.959 18.0273L13.252 7.58789C13.2617 7.24609 13.0176 7.01172 12.6465 7.01172C12.2949 7.01172 12.0508 7.23633 12.041 7.58789L11.748 18.0273C11.7383 18.3691 11.9824 18.6035 12.3535 18.6035ZM5.16602 4.46289L6.71875 4.46289L6.71875 2.37305C6.71875 1.81641 7.10938 1.45508 7.69531 1.45508L11.1914 1.45508C11.7773 1.45508 12.168 1.81641 12.168 2.37305L12.168 4.46289L13.7207 4.46289L13.7207 2.27539C13.7207 0.859375 12.8027 0 11.2988 0L7.58789 0C6.08398 0 5.16602 0.859375 5.16602 2.27539ZM0.732422 5.24414L18.1836 5.24414C18.584 5.24414 18.9062 4.90234 18.9062 4.50195C18.9062 4.10156 18.584 3.76953 18.1836 3.76953L0.732422 3.76953C0.341797 3.76953 0 4.10156 0 4.50195C0 4.91211 0.341797 5.24414 0.732422 5.24414ZM4.98047 21.748L13.9355 21.748C15.332 21.748 16.2695 20.8398 16.3379 19.4434L17.0215 5.05859L15.4492 5.05859L14.7949 19.2773C14.7754 19.8633 14.3555 20.2734 13.7793 20.2734L5.11719 20.2734C4.56055 20.2734 4.14062 19.8535 4.11133 19.2773L3.41797 5.05859L1.88477 5.05859L2.57812 19.4531C2.64648 20.8496 3.56445 21.748 4.98047 21.748Z"/></svg>')
        ])
      ])
    )
  }
}

const sourcesRoute = async params => {
  $('#sources').style.display = ''
  $('body').style.overflowY = 'hidden'
  $('#sources').classList.remove('sheet-hide')
  $('#sources').parentNode.children[0].style.display = ''
  $('#sources').parentNode.children[0].classList.remove('sheet-hide')

  reloadSources()

  if (params.get('add'))
    addSource(decodeURIComponent(params.get('add')))
}

let searchAutoTimeout = -1
const doSearch = () => {
  if (searchAutoTimeout >= 0)
    clearTimeout(searchAutoTimeout)

  const search = $('#searchbox').value
  if (search.trim() == '')
    return route('?')
  route('?search=' + encodeURIComponent(search))
}
$('#searchform').addEventListener('submit', async ev => {
  ev.preventDefault()
  doSearch()
})
$('#searchbox').addEventListener('input', () => {
  if (searchAutoTimeout >= 0)
    clearTimeout(searchAutoTimeout)

  searchAutoTimeout = setTimeout(doSearch, 100)
})

if ('serviceWorker' in navigator)
  navigator.serviceWorker.register(new URL('sw.js', import.meta.url))

const route = uri => {
  history.pushState(null, null, uri)
  doroute(new URLSearchParams(uri))
}

const updateCertParams = () => {
  const params = new URLSearchParams(location.search)

  const hexkeymat = params.get('k')
  if (hexkeymat)
    localStorage.setItem('pwastore_keymat', hexkeymat)

  const hexserial = params.get('s')
  if (hexserial)
    localStorage.setItem('pwastore_serial', hexserial)

  const strexpiry = params.get('e')
  if (strexpiry)
    localStorage.setItem('pwastore_expiry', strexpiry)

  if (hexkeymat && hexserial && strexpiry)
    localStorage.setItem('pwastore_signedprofile', 'yes')
}

window.addEventListener('load', async () => {
  // initialize MixDB
  await db.init()

  // make sure the cert params are stored in localStorage
  // TODO this should use MixDB too in case of quota limit
  updateCertParams()

  await setupBrowse()
  setupSettings()

  if (localStorage.getItem('pwastore_autorefresh')) {
    refreshAllSources()
  } else if (localStorage.getItem('pwastore_version') != VERSION) {
    localStorage.setItem('pwastore_version', VERSION)
    refreshAllSources()
  }

  if (!localStorage.getItem('pwastore_firsttime')) {
    //route('?')
    localStorage.setItem('pwastore_firsttime', true)
    $('#first-time-dialog').style.display = ''
    $('#ftd-done-btn').addEventListener('click', () => $('#first-time-dialog').style.display = 'none')
  }

  $('#tab-explore').addEventListener('click', () => route('?'))
  $('#tab-settings').addEventListener('click', () => route('?settings=1'))

  $('#app-back-btn').addEventListener('click', () => route('?'))
  $('#cstmz-done-btn').addEventListener('click', hideCustomize)

  $('#srcs-open-btn').addEventListener('click', () => route('?sources=1'))
  $('#srcs-done-btn').addEventListener('click', hideSources)
  $('#srcs-add-btn').addEventListener('click', () => addSource(prompt('Add Source from URL')))

  $('#src-refresh-btn').addEventListener('click', refreshAllSources)

  let portrait = window.innerHeight > window.innerWidth
  let aspect = portrait ? window.innerHeight / window.innerWidth : window.innerWidth / window.innerHeight

  // 16:9 vs 19.5:9
  if (aspect > 17 / 9) {
    $('.tabs').style.paddingBottom = '1rem'
  }

  doroute(new URLSearchParams(location.search))
})


const doroute = async params => {
  const updateDOM = async () => {
    $('#tab-explore').classList.add('tab-selected')
    $('#tab-settings').classList.remove('tab-selected')

    // fix the empty sources bug maybe
    if ((await db.getAll('sources')).length < 1) {
      const json = await (await fetch(location.origin + '/core.json')).json()

      await db.put('sources', {
        id: 'app.pwastore.core',
        icon: '/thirdparty/pwastore.png',
        name: 'PWAStore Core',
        url: location.origin + '/core.json',
        cache: json
      })
    }

    if (params.get('app')) {
      $('#explore').style.display = 'none'
      $('#settings').style.display = 'none'

      appRoute(params)
    } else if (params.get('settings')) {
      settingsRoute(params)
      $('#explore').style.display = 'none'
      $('#app').style.display = 'none'

      $('#tab-explore').classList.remove('tab-selected')
      $('#tab-settings').classList.add('tab-selected')
    } else if (params.get('sources')) {
      $('#app').style.display = 'none'
      $('#settings').style.display = 'none'

      browseRoute(params)
      sourcesRoute(params)
    } else {
      $('#app').style.display = 'none'
      $('#settings').style.display = 'none'

      browseRoute(params)
    }
  }

  if ('startViewTransition' in document) {
    document.startViewTransition(updateDOM)
  } else {
    await updateDOM()
  }
}

window.addEventListener('popstate', () => {
  doroute(new URLSearchParams(location.search))
})
