export const SER = new XMLSerializer()

export const VERSION = localStorage.getItem('pwastore_experiments') ? '2.1' : '2.0'
export const CODENAME = localStorage.getItem('pwastore_experiments') ? 'Alpinya' : 'Straltea'

if (!('randomUUID' in crypto))
  // https://stackoverflow.com/a/2117523/2800218
  // LICENSE: https://creativecommons.org/licenses/by-sa/4.0/legalcode
  crypto.randomUUID = function randomUUID() {
    return (
      [1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,
      c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
}

if (!('URLSearchParams' in window)) {
  window.URLSearchParams = function(q) {
    this.p = new Map()
    if (q) {
      q.replace(/^\?/, '').split('&').forEach(i => {
        const [k, v] = i.split('=').map(decodeURIComponent)
        this.p.set(k, [...(this.p.get(k) || []), v])
        this[k] = this.p.get(k)
      })
    }
  }
  window.URLSearchParams.prototype.get = function(k) {
    return (this.p.get(k) || [])[0] || null
  }
  window.URLSearchParams.prototype.has = function(k) {
    return this.p.has(k)
  }
  window.URLSearchParams.prototype.toString = function() {
    return [...this.p.entries()].map(([k, v]) =>
      v.map(v => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&')
    ).join('&')
  }
}

export function download(name, url, revoke=true) {
  const a = document.createElement('a')
  a.download = name
  a.href = url
  document.body.append(a)
  a.click()
  document.body.removeChild(a)
  a.remove()
  if (revoke)
    setTimeout(() => URL.revokeObjectURL(url), 1000)
}

export function AppleUUID() {
  return crypto.randomUUID().toUpperCase()
}

export function node(doc, tag, content) {
  const n = doc.createElement(tag)
  if (content) n.appendChild(doc.createTextNode(content))
  return n
}

// https://stackoverflow.com/a/47317538
var prettifyXml = function(xmlDoc) {
    var xsltDoc = new DOMParser().parseFromString([
        // describes how we want to modify the XML - indent everything
        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
        '  <xsl:strip-space elements="*"/>',
        '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
        '    <xsl:value-of select="normalize-space(.)"/>',
        '  </xsl:template>',
        '  <xsl:template match="node()|@*">',
        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
        '  </xsl:template>',
        '  <xsl:output indent="yes"/>',
        '</xsl:stylesheet>',
    ].join('\n'), 'application/xml')

    var xsltProcessor = new XSLTProcessor()
    xsltProcessor.importStylesheet(xsltDoc)
    var resultDoc = xsltProcessor.transformToDocument(xmlDoc)
    var resultXml = new XMLSerializer().serializeToString(resultDoc)
    return resultXml
}

export class Profile {
  constructor(id, name, desc, ver, org='PWA Store', uuid=AppleUUID()) {
    this.id = id
    this.ver = ver
    this.doc = document.implementation.createDocument("", "", null)

    this.plist = node(this.doc, 'plist')
    this.plist.setAttribute('version', '1.0')
    this.dict = node(this.doc, 'dict')
    this.plist.append(this.dict)
    this.doc.append(this.plist)

    this.array = node(this.doc, 'array')
    this.dict.append(node(this.doc, 'key', 'PayloadContent'))
    this.dict.append(this.array)

    this.dict.append(node(this.doc, 'key', 'PayloadDescription'))
    this.dict.append(node(this.doc, 'string', desc))
    this.dict.append(node(this.doc, 'key', 'PayloadDisplayName'))
    this.dict.append(node(this.doc, 'string', name))
    this.dict.append(node(this.doc, 'key', 'PayloadIdentifier'))
    this.dict.append(node(this.doc, 'string', id))
    this.dict.append(node(this.doc, 'key', 'PayloadOrganization'))
    this.dict.append(node(this.doc, 'string', org))
    this.dict.append(node(this.doc, 'key', 'PayloadRemovalDisallowed'))
    this.dict.append(node(this.doc, 'false'))
    this.dict.append(node(this.doc, 'key', 'PayloadType'))
    this.dict.append(node(this.doc, 'string', 'Configuration'))
    this.dict.append(node(this.doc, 'key', 'PayloadUUID'))
    this.dict.append(node(this.doc, 'string', uuid))
    this.dict.append(node(this.doc, 'key', 'PayloadVersion'))
    this.dict.append(node(this.doc, 'integer', ver))
  }

  certificatePEM(name, data, ver, uuid=AppleUUID()) {
    const dict = node(this.doc, 'dict')

    dict.append(node(this.doc, 'key', 'PayloadCertificateFileName'))
    dict.append(node(this.doc, 'string', name))

    dict.append(node(this.doc, 'key', 'PayloadContent'))
    dict.append(node(this.doc, 'data', data))

    dict.append(node(this.doc, 'key', 'PayloadDescription'))
    dict.append(node(this.doc, 'string', 'Configures a Certificate'))

    dict.append(node(this.doc, 'key', 'PayloadDisplayName'))
    dict.append(node(this.doc, 'string', name))

    dict.append(node(this.doc, 'key', 'PayloadIdentifier'))
    dict.append(node(this.doc, 'string', 'app.pwastore.cert.' + uuid))

    dict.append(node(this.doc, 'key', 'PayloadType'))
    dict.append(node(this.doc, 'string', 'com.apple.security.pem'))

    dict.append(node(this.doc, 'key', 'PayloadUUID'))
    dict.append(node(this.doc, 'string', uuid))

    dict.append(node(this.doc, 'key', 'PayloadVersion'))
    dict.append(node(this.doc, 'integer', ver))

    this.array.append(dict)
    return this
  }

  certificateRoot(name, der, ver, uuid=AppleUUID()) {
    const dict = node(this.doc, 'dict')

    dict.append(node(this.doc, 'key', 'PayloadCertificateFileName'))
    dict.append(node(this.doc, 'string', name))

    dict.append(node(this.doc, 'key', 'PayloadContent'))
    dict.append(node(this.doc, 'data', btoa(String.fromCharCode.apply(null, der)).match(/.{1,64}/g).join('\n')))

    dict.append(node(this.doc, 'key', 'PayloadDescription'))
    dict.append(node(this.doc, 'string', 'Configures a Root Certificate'))

    dict.append(node(this.doc, 'key', 'PayloadDisplayName'))
    dict.append(node(this.doc, 'string', name))

    dict.append(node(this.doc, 'key', 'PayloadIdentifier'))
    dict.append(node(this.doc, 'string', 'app.pwastore.cert.' + uuid))

    dict.append(node(this.doc, 'key', 'PayloadType'))
    dict.append(node(this.doc, 'string', 'com.apple.security.root'))

    dict.append(node(this.doc, 'key', 'PayloadUUID'))
    dict.append(node(this.doc, 'string', uuid))

    dict.append(node(this.doc, 'key', 'PayloadVersion'))
    dict.append(node(this.doc, 'integer', ver))

    this.array.append(dict)
    return this
  }

  webClip(name, url, iconb64, ver, uuid=AppleUUID(), scoped=false) {
    const dict = node(this.doc, 'dict')

    dict.append(node(this.doc, 'key', 'FullScreen'))
    dict.append(node(this.doc, 'true'))

    if (iconb64) {
      dict.append(node(this.doc, 'key', 'Icon'))
      dict.append(node(this.doc, 'data', iconb64))
    }

    dict.append(node(this.doc, 'key', 'IgnoreManifestScope'))
    dict.append(node(this.doc, scoped ? 'true' : 'false'))

    dict.append(node(this.doc, 'key', 'IsRemovable'))
    dict.append(node(this.doc, 'true'))

    dict.append(node(this.doc, 'key', 'Label'))
    dict.append(node(this.doc, 'string', name))

    dict.append(node(this.doc, 'key', 'PayloadDescription'))
    dict.append(node(this.doc, 'string', 'Configures a PWA clip'))

    dict.append(node(this.doc, 'key', 'PayloadDisplayName'))
    dict.append(node(this.doc, 'string', name))

    dict.append(node(this.doc, 'key', 'PayloadIdentifier'))
    dict.append(node(this.doc, 'string', 'com.apple.webClip.managed.' + uuid))

    dict.append(node(this.doc, 'key', 'PayloadType'))
    dict.append(node(this.doc, 'string', 'com.apple.webClip.managed'))

    dict.append(node(this.doc, 'key', 'PayloadUUID'))
    dict.append(node(this.doc, 'string', uuid))

    dict.append(node(this.doc, 'key', 'PayloadVersion'))
    dict.append(node(this.doc, 'integer', ver))

    dict.append(node(this.doc, 'key', 'Precomposed'))
    dict.append(node(this.doc, 'false'))

    dict.append(node(this.doc, 'key', 'URL'))
    dict.append(node(this.doc, 'string', url))

    this.array.append(dict)
    return this
  }

  string() {
    return `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd">
` + prettifyXml(this.doc)
  }

  blob() {
    return new Blob([ this.string() ], { type: 'application/x-apple-aspen-config' })
  }

  url() {
    return URL.createObjectURL(this.blob())
  }
}

export function _arrayBufferToBase64(buffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

// hacky way to support different kinds of icon
// i hate cors btw
export async function fetchIcon(url, opt) {
  const f = await fetch(url, opt)
  const d = await f.arrayBuffer()

  if (f.headers.get('Content-Type')?.includes('svg')) {
    const svgurl = URL.createObjectURL(new Blob([d], { type: 'image/svg+xml' }))
    const svg = document.createElement('img')
    svg.width = svg.height = '64px'
    svg.src = svgurl

    return await new Promise((res) => {
      svg.onload = () => {
        const cnv = document.createElement('canvas')
        cnv.width = cnv.height = 64
        const ctx = cnv.getContext('2d')
        ctx.drawImage(svg, 0, 0)
        cnv.toBlob(async blob => {
          URL.revokeObjectURL(svgurl)
          res(_arrayBufferToBase64(await blob.arrayBuffer()))
        })
      }
    })
  }

  return _arrayBufferToBase64(d)
}

// https://stackoverflow.com/a/43467144
export function isurl(str) {
  let url
  try {
    url = new URL(str)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export function escape(text) {
  return text?.replace(/&/g, '&amp;')?.replace(/</g, '&lt;')?.replace(/>/g, '&gt;')?.replace(/\n/g, '<br>')
}

window.$ = s => document.querySelector(s)
