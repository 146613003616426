const STORE_KEY = Symbol('MixDB@@StoreKey')

export class MixDB {
  constructor(name, version='1') {
    this.name = name
    this.version = version
    this.connectors = []
    this.stores = {}
  }

  link(connector) {
    this.connectors.push(connector)
    return this
  }

  store(name, key) {
    this.stores[name] = { [STORE_KEY]: key }
    return this
  }

  async init() {
    // init connectors and populate data
    for (const conn of this.connectors) {
      try {
        await conn.init(this)
      } catch (e) {
        console.warn(`MixDB: Error initializing connector "${conn.constructor.name}", skipping`)
        console.error(e)
        conn.ready = false
      }

      // retrieve the connector
      if (conn.ready)
        for (const store in this.stores) {
          const data = await conn.retrieve(store)
          for (const obj of data)
            this.put(store, obj, false)
        }
    }
  }

  async get(store, key) {
    store = this.stores[store]
    if (!store)
      throw 'MixDB: Store not found'
    return store[key]
  }

  async put(storeName, obj, update=true) {
    const store = this.stores[storeName]
    if (!store)
      throw 'MixDB: Store not found'
    const keyName = store[STORE_KEY]
    const key = obj[keyName]
    if (!key)
      throw `MixDB: Object does not have key property "${keyName}"`

    // execute connectors
    if (update) {
      for (const conn of this.connectors) {
        try {
          conn.ready && await conn.update(storeName, obj)
        } catch (e) {
          console.warn(e)
          conn.ready = false // if error, invalidate the connector
        }
      }
    }

    store[key] = obj
  }

  async getAll(store) {
    store = this.stores[store]
    if (!store)
      throw 'MixDB: Store not found'
    return Object.values(store)
  }
}

export class Connector {
  ready = false

  async init() {
    throw 'not implemented'
  }

  async update() {
    throw 'not implemented'
  }

  async retrieve() {
    throw 'not implemented'
  }
}

export class IDBConnector extends Connector {
  constructor(openFn, opts={}) {
    super()
    this.openFn = openFn
    this.opts = opts
  }

  async init(db) {
    this.db = db
    this.idb = await this.openFn(db.name, db.version, this.opts)
    this.ready = true
  }

  retrieve(storeName) {
    return this.idb?.getAll(storeName)
  }

  update(storeName, obj) {
    return this.idb?.put(storeName, obj)
  }
}

export class StorageConnector extends Connector {
  constructor(storage) {
    super()
    this.storage = storage
  }

  async init(db) {
    this.db = db
    this.ready = true
  }

  async retrieve(storeName) {
    const store = this.db.stores[storeName]
    if (!store)
      throw 'MixDB Connector: Store not found'

    const json = this.storage.getItem(`mixdb_${this.db.name}_${storeName}`) || '[]'
    return JSON.parse(json)
  }

  async update(storeName) {
    const store = this.db.stores[storeName]
    if (!store)
      throw 'MixDB Connector: Store not found'

    // have to update the whole thing smh
    this.storage.setItem(
      `mixdb_${this.db.name}_${storeName}`,
      JSON.stringify(Object.values(store))
    )
  }
}
